<template>
  <div class="speaker">
    <Title :title="title" :description="description"></Title>
    <Chairman></Chairman>
    <div class="speaker-child">
      <div class="bg-line">
        <p>演讲嘉宾</p>
        <img class="pc" src="../assets/img/title-bg.png" alt="" />
        <img class="mob" src="../assets/img/mob-title-bg.png" alt="" />
      </div>
      <ul>
        <li
          v-for="(item, index) in speakerList"
          :key="index"
          @click="toTeacher(item.id)"
        >
          <div class="picture">
            <img
              class="avtar"
              :src="
                splitUrlSuffix(item.thumbs) + '?imageView2/1/w/220/h/241/q/75'
              "
              alt=""
            />
            <div class="more-wrapper"></div>
            <div class="more">
              <span>更多</span>
              <img src="../assets/img/right-icon.png" alt="" />
            </div>
          </div>
          <div class="triangle">
            <img src="../assets/img/top-triangle.png" alt="" />
          </div>
          <div class="info">
            <h4>{{ item.name }}</h4>
            <p>{{ item.company }} {{ item.position }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";
import Chairman from "@/components/Chairman";
import { sid, getLecturerList } from "@/utils/api.js";

export default {
  components: {
    Title,
    Chairman,
  },
  data() {
    return {
      title: "100余位国内外技术专家",
      description: "带领技术团队走向未来的变革者",
      speakerList: [],
    };
  },
  mounted() {
    this.getSpeaker();
  },
  methods: {
    toTeacher(id) {
      this.$router.push({ path: "/2022sz/teacher", query: { id: id } });
    },
    splitUrlSuffix(url) {
      return url.split("?")[0];
    },
    getSpeaker() {
      this.$http
        .jsonp(getLecturerList + sid(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log(res);
          if (res.errno == 0) {
            this.speakerList = res.data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.speaker {
  margin: 0 auto;
  .chairman-wrapper {
    border-color: transparent;
  }
  .speaker-child {
    width: 1202px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 91px;
    padding-bottom: 80px;
    border-bottom: 1px solid #f1f4f7;
    .bg-line {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 55px;
      position: relative;
      width: 590px;
      height: 5px;
      padding: 12px 0;
      p {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        text-align: center;
        font-family: PingFang SC;
        color: #ffffff;
        z-index: 10;
      }
      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        margin-right: 25px;
        display: flex;
        flex-direction: column;
        padding-bottom: 22px;
        .picture {
          height: 241px;
          position: relative;
          .avtar {
            width: 220px;
            height: 241px;
            border-radius: 7px;
            border: 1px solid #f7f7f7;
          }
          .more-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
            width: 220px;
            height: 241px;
          }
          .more {
            position: absolute;
            top: 0;
            left: 0;
            width: 220px;
            height: 241px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            span {
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
            img {
              width: 30px;
              height: 30px;
              vertical-align: text-bottom;
            }
          }
        }
        &:hover {
          .more-wrapper {
            border-radius: 7px;
            display: block;
            background: #00183b;
            opacity: 0.4;
          }
          .more {
            opacity: 1;
          }
        }
        .triangle {
          text-align: left;
          padding-top: 8px;
          padding-bottom: 10px;
          line-height: 12px;
        }
        .info {
          padding-bottom: 133px;
          h4 {
            width: 220px;
            margin-bottom: 9px;
            text-align: left;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #010101;
            line-height: 29px;
          }
          p {
            width: 196px;
            font-size: 16px;
            font-family: PingFang SC;
            // font-weight: 500;
            text-align: left;
            color: #666666;
            line-height: 24px;
          }
        }
      }
      li:nth-last-child(1),
      li:nth-last-child(2),
      li:nth-last-child(3),
      li:nth-last-child(4) {
        border-bottom: 1px dashed transparent;
      }
    }
  }
}
@media (min-width: 1024px) {
  .pc {
    display: block;
  }
  .mob {
    display: none;
  }
  .speaker-child {
    ul {
      li:nth-child(2),
      li:nth-child(4),
      li:nth-child(7),
      li:nth-child(9),
      li:nth-child(12),
      li:nth-child(14),
      li:nth-child(17),
      li:nth-child(19) {
        flex-direction: column-reverse;
        border-bottom: 1px dashed #fdfdfd;
        .triangle {
          img {
            transform: rotate(180deg);
          }
        }
        .info {
          padding-bottom: 0;
          padding-top: 74px;
        }
      }
      li:nth-child(5),
      li:nth-child(10),
      li:nth-child(15),
      li:nth-child(20) {
        margin-right: 0;
      }
      li:nth-last-child(1),
      li:nth-last-child(2),
      li:nth-last-child(3),
      li:nth-last-child(4) {
        border-bottom: 1px dashed transparent;
      }
    }
  }
}
@media (max-width: 1023px) {
  .pc {
    display: none;
  }
  .mob {
    display: block;
  }
  .speaker {
    margin-top: 0;
    .speaker-child {
      width: 100vw;
      padding-top: 40px;
      .bg-line {
        width: auto;
        min-height: 20px;
        height: auto;
        margin-bottom: 25px;
        p {
          font-size: 12px;
          line-height: 20px;
          height: 20px;
        }
      }
      ul {
        padding-left: 7vw;
        padding-right: 7vw;
        li {
          flex-direction: column;
          width: 27vw;
          margin-right: 2.5vw;
          padding-bottom: 11px;
          .picture {
            height: auto;
            .avtar {
              width: 100%;
              height: auto;
            }
            .more-wrapper,
            .more {
              width: 100%;
              height: 100%;
              span {
                font-size: 15px;
              }
              img {
                width: 15px;
                height: 15px;
              }
            }
          }
          .triangle {
            padding-top: 6px;
            padding-bottom: 7px;
          }
          .info {
            padding-bottom: 20px;
            h4,
            p {
              width: 100%;
              line-height: normal;
            }
            h4 {
              font-size: 15px;
              margin-bottom: 5px;
            }
            p {
              font-size: 10px;
            }
          }
        }
      }
      li:nth-child(3n + 2) {
        flex-direction: column-reverse;
        border-bottom: 1px dashed #fdfdfd;
        .triangle {
          img {
            transform: rotate(180deg);
          }
        }
        .info {
          padding-bottom: 0;
          padding-top: 74px;
        }
      }
      li:nth-child(3n) {
        margin-right: 0;
      }
    }
    .publisher {
      padding-top: 30px;
    }
    .loading {
      padding-top: 54px;
      padding-bottom: 61px;
    }
  }
}
</style>