<template>
  <div class="speaker_">
    <Title :title="title" :description="description"></Title>
    <div class="giac-container">
      <div class="main">
        <Speakers :_speakers="speakerList"></Speakers>
      </div>
    </div>
  </div>
</template>

<script>
import {getSueLecturer, sidlecturer1, sidlecturer2, sidlecturer3, getLecturerList, sid} from "@/utils/api.js";

export default {
  name: "Speaker_",
  components: {
    Title: ()=>import("@/components/Title"),
    Speakers: ()=>import("@/components/Speakers"),
  },
  data() {
    return {
      title: "100余位国内外技术专家",
      description: "带领技术团队走向未来的变革者",
      speakerList: {},
    }
  },
  created() {
    this.getChairman();
    this.getProducer();
    this.getSelection();
    this.getSpeaker();
  },
  methods: {
    getChairman: function() {
      this.speakerList.chairman = {
        title: "联席主席",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      // this.speaker = {};
      // this.speaker.chairman = false;
      this.$http
        .jsonp(getSueLecturer + sidlecturer1(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.errno == "0") {
            this.speakerList.chairman.list = res.data[sidlecturer1(this.global.year)]
              ? res.data[sidlecturer1(this.global.year)]
              : [];
            // this.speaker.chairman = true;
            this.$forceUpdate();
          }
        });
    },
    getProducer: function() {
      this.speakerList.producer = {
        title: "出品人",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      // this.speaker.speaker = false;
      this.$http
        .jsonp(getSueLecturer + sidlecturer2(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.errno == 0) {
            this.speakerList.producer.list = res.data[sidlecturer2(this.global.year)]
              ? res.data[sidlecturer2(this.global.year)]
              : [];
            // this.speaker.speaker = true;
            this.$forceUpdate();
          }
        });
    },
    getSelection() {
      this.speakerList.selection = {
        title: "选题委员会",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      this.$http
        .jsonp(getSueLecturer + sidlecturer3(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.errno == 0) {
            this.speakerList.selection.list = res.data[sidlecturer3(this.global.year)];
          }
        });
    },
    getSpeaker() {
      this.speakerList.speaker = {
        title: "演讲嘉宾",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      this.$http
        .jsonp(getLecturerList + sid(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.errno == 0) {
            this.speakerList.speaker.list = res.data;
          }
        });
    },
  },
}
</script>

<style lang="scss">
.speaker_ {
  padding-bottom: 80px;
  border-bottom: 1px solid #f1f4f7;
  .giac-container .main {
    width: 100%;
    max-width: 1400px;
    padding: 0 25px;
    margin: 0 auto;
  }
}
@media (max-width:500px) {
  .speaker_ .giac-container .main {
    padding: 0;
  }
}
</style>