<template>
  <div class="speaker_">
    <Title :title="title" :description="description"></Title>
    <div class="giac-container">
      <div class="main">
        <Speakers :_speakers="speakerList"></Speakers>
      </div>
    </div>
  </div>
</template>

<script>
import {sid, producersFromTheme, getSueLecturer, sidlecturer1, sidlecturer2, sidlecturer3, getLecturerList, getSpeechInfoFin} from "@/utils/api.js";

export default {
  name: "Speaker_",
  components: {
    Title: ()=>import("@/components/Title"),
    Speakers: ()=>import("@/components/Speakers"),
  },
  data() {
    let _this = this;
    return {
      title: "100余位国内外技术专家",
      description: "带领技术团队走向未来的变革者",
      speakerList: {},
      producersFromTheme: producersFromTheme(_this.global.year),
    }
  },
  created() {
    this.getChairman();
    this.getThemeList();
    this.getSelection();
    this.getSpeaker();
  },
  methods: {
    getChairman: function() {
      this.speakerList.chairman = {
        title: "联席主席",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      // this.speaker = {};
      // this.speaker.chairman = false;
      this.$http
        .jsonp(getSueLecturer + sidlecturer1(this.global.year))
        .then(data => {
          return data.json();
        })
        .then(res => {
          // console.log(res);
          if (res.errno == "0") {
            this.speakerList.chairman.list = res.data[sidlecturer1(this.global.year)]
              ? res.data[sidlecturer1(this.global.year)]
              : [];
            // this.speaker.chairman = true;
            this.$forceUpdate();
          }
        });
    },
    getProducer: function() {
      if (sidlecturer2(this.global.year)) {
        this.$http
          .jsonp(getSueLecturer + sidlecturer2(this.global.year))
          .then(data => {
            return data.json();
          })
          .then(res => {
            // console.log(res);
            if (res.errno == 0) {
              this.speakerList.producer.list = res.data[sidlecturer2(this.global.year)]
                ? res.data[sidlecturer2(this.global.year)]
                : [];
              // this.speaker.speaker = true;
              this.$forceUpdate();
            }
          });
      }
    },
    getSelection() {
      this.speakerList.selection = {
        title: "选题委员会",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      this.$http
        .jsonp(getSueLecturer + sidlecturer3(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.errno == 0) {
            this.speakerList.selection.list = res.data[sidlecturer3(this.global.year)];
          }
        });
    },
    getSpeaker() {
      this.speakerList.speaker = {
        title: "演讲嘉宾",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      this.$http
        .jsonp(getLecturerList + sid(this.global.year))
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.errno == 0) {
            this.speakerList.speaker.list = res.data;
          }
        });
    },
    getThemeList () {
      this.speakerList.producer = {
        title: "出品人",
        desc: "带领技术团队走向未来的变革者",
        list: []
      };
      // this.speaker.speaker = false;
      if (!this.producersFromTheme) {
        this.getProducer();
      } else {
        this.$http
          .jsonp(getSpeechInfoFin + sid(this.global.year))
          .then(data => {
            return data.json();
          })
          .then(res => {
            // console.log('get_Theme ', res)
            if (res.errno == 0 && res.data && res.data.length > 0) {
              let arr = []
              for (let item of res.data) {
                if (item.producerInfo && item.producerInfo.length > 0) {
                  let ids = (item.producerId && item.producerId.split(',')) || []
                  for (let index in item.producerInfo) {
                    let producer = item.producerInfo[index]
                    this.speakerList.producer.list.push({
                      company: producer.company,
                      content: producer.id || ids[index] || '',
                      content_note: producer.name,
                      // created_at: "1742528586",
                      // desc: null,
                      description: producer.description,
                      // detailType: null,
                      // file: "[]",
                      id: producer.id || ids[index] || '',
                      // isDeleted: "0",
                      name: producer.name,
                      position: producer.position,
                      // recommendId: "409",
                      // sort: "4370",
                      thumbs: (producer.thumbs && this.global.formatThumbs(producer.thumbs)) || '',
                      title: null,
                      // type: "0",
                      // updated_at: "1742528586"
                    })
                  }
                }
              }
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
.speaker_ {
  padding-bottom: 80px;
  border-bottom: 1px solid #f1f4f7;
  .giac-container .main {
    width: 100%;
    max-width: 1400px;
    padding: 0 25px;
    margin: 0 auto;
  }
}
@media (max-width:500px) {
  .speaker_ .giac-container .main {
    padding: 0;
  }
}
</style>